var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-card',[_c('template',{slot:"title"},[_c('strong',[_vm._v("Laporan Register Nasabah")]),_c('a-button',{staticClass:"btn btn-outline-primary pull-right",on:{"click":_vm.getAllData}},[_vm._v("Refresh Data")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-3 col-sm-3 col-md-3 col-lg-3"},[_vm._v("Tanggal")]),_c('div',{staticClass:"col-xs-4 col-sm-4 col-md-4 col-lg-4"},[_vm._v("Cabang")]),_c('div',{staticClass:"col-xs-4 col-sm-4 col-md-4 col-lg-4"},[_vm._v("Filter")]),_c('div',{staticClass:"col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2"},[_c('a-range-picker',{attrs:{"format":'DD-MM-YYYY',"default-value":[_vm.moment().startOf('month'), _vm.moment().endOf('month')]},on:{"change":_vm.rangedofunction}})],1),_c('div',{staticClass:"col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-2"},[_c('v-select',{staticClass:"style-chooser",attrs:{"clearable":true,"placeholder":"Cabang","label":"keterangan","options":_vm.dataCabang,"reduce":(tes) => tes.kode},on:{"input":_vm.selectDataCabang},model:{value:(_vm.cabang),callback:function ($$v) {_vm.cabang=$$v},expression:"cabang"}})],1),_c('div',{staticClass:"col-xs-2 col-sm-2 col-md-2 col-lg-2 mb-2"},[_c('a-input',{attrs:{"placeholder":"Filter"},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('div',{staticClass:"col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2"},[_c('a-checkbox',{on:{"change":_vm.getAllData},model:{value:(_vm.badanusaha),callback:function ($$v) {_vm.badanusaha=$$v},expression:"badanusaha"}},[_vm._v(" Badan Usaha ")])],1)]),_c('a-table',{attrs:{"columns":_vm.columnDefs,"dataSource":_vm.rowData,"scroll":{ x: 1000, y: 400 },"pagination":false,"size":"small","loading":_vm.tableLoading},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.dataIndex}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
              .toString()
              .split(
                new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
              )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[_vm._v(" "+_vm._s(text)+" ")]]}},{key:"name",fn:function(text){return _c('a',{attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(text))])}},{key:"tags",fn:function(tags){return _c('span',{},_vm._l((tags),function(tag){return _c('a-tag',{key:tag,attrs:{"color":"blue"}},[_vm._v(_vm._s(tag))])}),1)}},{key:"tgl",fn:function(text){return _c('span',{},[_vm._v(" "+_vm._s(_vm.formatDate(text))+" ")])}}])},[_c('span',{attrs:{"slot":"customTitle"},slot:"customTitle"},[_c('a-icon',{attrs:{"type":"smile-o"}}),_vm._v(" Kode")],1)]),_c('a-pagination',{staticClass:"mt-4",attrs:{"size":"small","page-size":_vm.currentPageSize,"total":_vm.totalpaginationdata,"pageSizeOptions":['10', '30', '50'],"show-size-changer":"","show-total":(total, range) => `${range[0]}-${range[1]} of ${total} items`},on:{"update:pageSize":function($event){_vm.currentPageSize=$event},"update:page-size":function($event){_vm.currentPageSize=$event},"showSizeChange":_vm.showSizeChangePaginationFunc,"change":_vm.changePaginationFunc},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }