<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong>Laporan Register Nasabah</strong>
          <a-button class="btn btn-outline-primary pull-right" @click="getAllData">Refresh Data</a-button>
        </template>
      <div class="row">
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">Tanggal</div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">Cabang</div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">Filter</div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
          <a-range-picker class="" :format="'DD-MM-YYYY'" :default-value="[moment().startOf('month'), moment().endOf('month')]" @change="rangedofunction"/>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mb-2">
          <v-select
            :clearable="true"
            class="style-chooser"
            v-model="cabang"
            placeholder="Cabang"
            label="keterangan"
            :options="dataCabang"
            :reduce="(tes) => tes.kode"
            @input="selectDataCabang"
          />
          <!-- <a-input class="" placeholder="Cabang" v-model="cabang"></a-input> -->
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 mb-2">
          <!-- <v-select
            :clearable="true"
            class="style-chooser"
            v-model="filter"
            placeholder="Filter"
            label="kode"
            :options="dataFilter"
            :reduce="(tes) => tes.kode"
            @input="getAllData"
          /> -->
          <a-input class="" placeholder="Filter" v-model="filter"></a-input>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mb-2">
          <a-checkbox v-model="badanusaha" @change="getAllData">
            Badan Usaha
          </a-checkbox>
          <!-- <a-input class="" placeholder="Badan Usaha" v-model="badanusaha"></a-input> -->
        </div>
      </div>
      <a-table
        :columns="columnDefs"
        :dataSource="rowData"
        :scroll="{ x: 1000, y: 400 }"
        :pagination="false"
        size="small"
        :loading="tableLoading"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="tgl" slot-scope="text">
          {{ formatDate(text) }}
        </span>
      </a-table>
      <a-pagination class="mt-4" size="small" v-model="currentPage" :page-size.sync="currentPageSize" :total="totalpaginationdata" :pageSizeOptions="['10', '30', '50']" @showSizeChange="showSizeChangePaginationFunc" @change="changePaginationFunc" show-size-changer :show-total="(total, range) => `${range[0]}-${range[1]} of ${total} items`"/>
      </a-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  mounted () {
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    this.getAllMaster()
    this.cabang = this.user.user_branch
  },
  data() {
    return {
      tableLoading: false,
      currentPage: 1,
      currentPageSize: 10,
      totalpaginationdata: 0,
      searchText: '',
      tgl1: moment().startOf('month').format('YYYY-MM-DD'),
      tgl2: moment().endOf('month').format('YYYY-MM-DD'),
      searchInput: null,
      searchedColumn: '',
      cabang: '',
      filter: '',
      badanusaha: false,

      dataBadanUsaha: [],
      dataCabang: [],
      dataFilter: [],

      selectedData: {},
      tablequickfilter: '',
      gridOptions: null,
      columnDefs: [
        {
          title: 'Tanggal',
          dataIndex: 'tglregister',
          scopedSlots: { customRender: 'tgl' },
          width: 100,
        },
        {
          title: 'Kode',
          dataIndex: 'kode',
          key: 'kode',
          width: 100,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kode.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Nama',
          dataIndex: 'nama',
          rowKey: 'nama',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Nama Pekerjaan',
          dataIndex: 'nama_pekerjaan',
          rowKey: 'nama_pekerjaan',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.nama_pekerjaan.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Alamat',
          dataIndex: 'alamat',
          rowKey: 'alamat',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.alamat.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Kode Pos',
          dataIndex: 'kodePos',
          rowKey: 'kodePos',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.kodePos.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Telepon',
          dataIndex: 'telepon',
          rowKey: 'telepon',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.telepon.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Cabang Entry',
          dataIndex: 'cabangEntry',
          rowKey: 'cabangEntry',
          width: 120,
          // fixed: 'left',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.cabangEntry.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
      ],
      rowData: [],

      componentKey: 0,
    }
  },
  methods: {
    moment,
    rangedofunction(date, dateString) {
      this.tgl1 = date[0].format('YYYY-MM-DD')
      this.tgl2 = date[1].format('YYYY-MM-DD')
      this.getAllMaster()
    },
    async getAllData() {
      this.tableLoading = true
      // console.log('this.currentPageSize', this.currentPageSize)
      // console.log('this.currentPage', this.currentPage)
      var bu = this.badanusaha ? 1 : 0
      var response = await lou.customUrlGet('lapregisternasabah?tglawal=' + this.tgl1 + '&tglakhir=' + this.tgl2 + '&cabang=' + this.cabang + '&filter=' + this.filter + '&page=' + this.currentPage + '&perpage=' + this.currentPageSize + '&badanusaha=' + bu)
      if (response) {
        this.tableLoading = false
        this.rowData = response.data
        this.totalpaginationdata = response.totaldata || 0
      } else {
        this.tableLoading = false
      }
    },
    selectDataCabang() {
      this.dataFilter = []
      var tgt = this.dataCabang.findIndex(x => x.kode === this.cabang)
      this.filter = this.dataCabang[tgt].filter
      // console.log('tgt', tgt)
      // var list = this.dataCabang[tgt].filter.split(',')
      // console.log('list', list)
      // list.forEach(element => {
      //   var ret = {
      //     kode: element,
      //   }
      //   this.dataFilter.push(ret)
      // })
      this.getAllData()
    },
    async getAllMaster() {
      var datacabang = await lou.customUrlGet(
        'lapregisternasabah/cabang?tglakhir=' + this.tgl2,
        false,
        false,
        false,
        false,
      )
      // var databadanusaha = await lou.customUrlGet(
      //   'LapRegisterNasabah/badanusaha',
      //   false,
      //   false,
      //   false,
      //   false,
      // )
      // if (jenisidentitas) {
      //   this.datajenisidentitas = jenisidentitas.data
      //   this.allMaster.jenisidentitas = jenisidentitas.data
      // }

      this.allMaster = {}

      if (datacabang) {
        var dao = []
        datacabang.data.forEach((element) => {
          var el = element.kode + ' - ' + element.keterangan
          var v = {
            ...element,
            keterangan: el,
            nama: element.keterangan,
          }
          dao.push(v)
        })
        // console.log('kont', kont)
        this.dataCabang = dao
        this.allMaster.ao = datacabang.data
      }

      // if (databadanusaha) {
      //   var keter = []
      //   databadanusaha.data.forEach((element) => {
      //     var el = element.kode + ' - ' + element.nama
      //     var v = {
      //       ...element,
      //       keterangan: el,
      //     }
      //     keter.push(v)
      //   })
      //   this.dataBadanUsaha = keter
      //   this.allMaster.keterkaitan = databadanusaha.data
      // }
      // this.getAllData()

      // setTimeout(() => {
      // this.addColumnDefs()
      // }, 1000)
    },
    changePaginationFunc(p1, p2) {
      this.currentPage = p1
      this.getAllData()
      // console.log('p1', p1)
      // console.log('p2', p2)
      // console.log('currentPage', this.currentPage)
    },
    showSizeChangePaginationFunc(p1, p2) {
      this.getAllData()
      // console.log('currentPageSize', this.currentPageSize)
      // console.log('p1', p1)
      // console.log('p2', p2)
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows[0].karat)
      // console.log('this.columnCurrency', this.columnCurrency)
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>
